import { createSlice } from '@reduxjs/toolkit';
// api
import { getRemotePunchCompanies } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  companies: [],
};

const slice = createSlice({
  name: 'remotePunchCompanies',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setRemotePunchCompanies(state, action) {
      state.companies = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setRemotePunchCompanies } = slice.actions;

// ----------------------------------------------------------------------

export function requestRemotePunchCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await getRemotePunchCompanies();
      dispatch(slice.actions.setRemotePunchCompanies(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

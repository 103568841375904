import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function deleteEstablishments(establishmentIds) {
  const response = await apiService.delete('establishments', {
    data: {
      establishment_ids: establishmentIds
    }
  });

  return response;
};